<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { useRouteQuery } from '@vueuse/router'
import { DateTime } from 'luxon'
import { P, match } from 'ts-pattern'

import type { SearchQuery } from '~/components/map/Searchbar.vue'
import type { Coordinates } from '~/utils/types'

import {
  AlArtistsGridSection,
  AlLocationsGridSection,
  AlOffersGridSection,
  AlRegionsGridSection,
} from '#components'

definePageMeta({
  authType: 'public',
  scrollToTop: (to, from) => {
    if (to.name === from.name && typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    return true
  },
  layoutProps: {
    solidHeader: true,
  },
})

const googleMapsUnlocked = ref(
  import.meta.dev ||
    useRuntimeConfig().public.disableGoogleMapsCookieCheck ||
    (import.meta.client && window.googleMapsUnlocked),
)

const displayType = useRouteQuery<'map' | 'grid'>('anzeige', 'map')

if (import.meta.client) {
  window.unblockGoogleMaps = () => {
    googleMapsUnlocked.value = true
  }
}

const search = reactive<SearchQuery>({
  name: '',
  city: '',
  during: null,
  type: [],
  artForm: [],
})

const bookAtelierOverride = computed(() => search.type.map((t) => t.id).includes('book-atelier'))

const route = useRoute()
const homePageType = computed(() =>
  match(route.path)
    .with(P.string.startsWith('/regionen'), () => 'region' as const)
    .with(P.string.startsWith('/kunstszene'), () => 'location' as const)
    .with(P.string.startsWith('/kuenstler-innen'), () => 'artist' as const)
    .otherwise(() => (bookAtelierOverride.value ? 'artist' : ('offer' as const))),
)

type HomePageDataType = ({
  __type: 'artist' | 'location' | 'region' | 'offer'
  id: string
  name: string
  thumbnail: {
    src: string
    alt: string
  }
  slug: string
  city?: string
} & (
  | { __type: 'artist' | 'location' | 'region' }
  | {
      __type: 'offer'
      artForm?: { id: string; name: string }
      offerType?: { id: string; name: string }
      offerAppointments: { timestamp: string }[]
    }
)) &
  Coordinates

const supabase = useSupabase()
const { data: homePageData, suspense: markerSuspense } = useQuery({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: ['map-markers', homePageType, bookAtelierOverride] as const,
  queryFn: async ({ queryKey: [_, type] }): Promise<HomePageDataType[]> => {
    const query = match([type, bookAtelierOverride.value])
      .with([P.union('artist', 'location', 'region'), P.boolean], ['offer', true], ([_table]) => {
        const table = _table === 'offer' ? 'artist' : _table // use artist for book-atelier

        return supabase
          .from(table)
          .select('*, address!inner(id, lat, lng, city), cms_page_section(component_name)')
          .not('address', 'is', null)
          .not('address.lat', 'is', null)
          .not('address.lng', 'is', null)
          .throwOnError()
          .then((res) => {
            let data = res.data
            if (!data) return []

            if (bookAtelierOverride.value) {
              data = data.filter((a) =>
                a.cms_page_section.map((s) => s.component_name).includes('BookAtelierForm'),
              )
            }

            const coords = [] as HomePageDataType[]
            for (const entity of data) {
              if (!entity.address?.lat || !entity.address.lng) continue
              coords.push({
                __type: table,
                id: entity.id,
                name: entity.name,
                thumbnail: {
                  src: entity.image_url,
                  alt: entity.image_alt,
                },
                slug: `${entity.slug}-${entity.slug_id}`,
                lat: Number.parseFloat(entity.address.lat),
                lng: Number.parseFloat(entity.address.lng),
                city: entity.address.city,
              })
            }
            return coords
          })
      })
      .with(['offer', false], async ([table]) => {
        function getOffersWith<const T extends 'location' | 'artist'>(relation: T) {
          return supabase
            .from(table)
            .select(
              `*, ${relation}!inner(id, address_id, address!inner(id, lat, lng, city)), art_form!inner(id, name), offer_type!inner(id, name), offer_appointment(timestamp)`,
            )
            .gt('offer_appointment.remaining_slots', 0) // also used in AppointmentStep.vue
            .gt(
              'offer_appointment.timestamp',
              DateTime.now().plus({ days: 1 }).startOf('day').toISO(),
            ) // also used in AppointmentStep.vue
            .not(`${relation}.address`, 'is', null)
            .not(`${relation}.address.lat`, 'is', null)
            .not(`${relation}.address.lng`, 'is', null)
            .not('disabled', 'eq', true)
        }

        const offersWithLocation = await getOffersWith('location')
          .throwOnError()
          .then((res) => res.data)

        const offersWithArtist = await getOffersWith('artist')
          .not('id', 'in', `(${offersWithLocation?.map((o) => o.id).join(',')})`)
          .throwOnError()
          .then((res) => res.data)

        const coords = [] as HomePageDataType[]

        const offerToCoord = (
          offer: NonNullable<typeof offersWithLocation | typeof offersWithArtist>[number],
          { lat, lng }: { lat: string; lng: string },
        ): HomePageDataType => ({
          __type: table,
          id: offer.id,
          name: offer.name,
          thumbnail: {
            src: offer.image_url,
            alt: offer.image_alt,
          },
          slug: `${offer.slug}-${offer.slug_id}`,
          lat: Number.parseFloat(lat),
          lng: Number.parseFloat(lng),
          artForm: offer.art_form ?? undefined,
          offerType: offer.offer_type ?? undefined,
          offerAppointments: offer.offer_appointment,
        })

        for (const offer of offersWithLocation ?? []) {
          const lat = offer.location?.address?.lat
          const lng = offer.location?.address?.lng
          if (!lat || !lng) continue

          coords.push({
            ...offerToCoord(offer, { lat, lng }),
            city: offer.location?.address?.city ?? undefined,
          })
        }

        for (const offer of offersWithArtist ?? []) {
          const lat = offer.artist?.address?.lat
          const lng = offer.artist?.address?.lng
          if (!lat || !lng) continue

          coords.push({
            ...offerToCoord(offer, { lat, lng }),
            city: offer.artist?.address?.city ?? undefined,
          })
        }

        return coords
      })
      .exhaustive()

    return query
  },
})

function filterByNameOrCity(entity: { name: string; city?: string }) {
  return (
    entity.name.toLowerCase().includes(search.name.toLowerCase()) &&
    (search.city.length > 0 && entity.city ? search.city.includes(entity.city) : true)
  )
}

const displayInfo = computed(() => {
  const data = homePageData.value?.filter(filterByNameOrCity) ?? []

  return match(useRoute().path)
    .with(P.string.startsWith('/regionen'), () => {
      return {
        component: AlRegionsGridSection,
        props: { regions: data },
        empty: data.length === 0,
        data,
      } as const
    })
    .with(P.string.startsWith('/kunstszene'), () => {
      return {
        component: AlLocationsGridSection,
        props: { locations: data },
        empty: data.length === 0,
        data,
      } as const
    })
    .with(P.string.startsWith('/kuenstler-innen'), () => {
      return {
        component: AlArtistsGridSection,
        props: { artists: data },
        empty: data.length === 0,
        data,
      } as const
    })
    .otherwise(() => {
      if (search.type.map((t) => t.id).includes('book-atelier')) {
        return {
          component: AlArtistsGridSection,
          props: { artists: data },
          empty: data.length === 0,
          data,
        } as const
      }

      const offers =
        data.filter((offer) => {
          if (offer.__type !== 'offer') return false

          return (
            filterByNameOrCity(offer) &&
            (search.artForm.length > 0 && offer.artForm
              ? search.artForm.map((af) => af.id).includes(offer.artForm.id)
              : true) &&
            (search.type.length > 0 && offer.offerType
              ? search.type.map((t) => t.id).includes(offer.offerType.id)
              : true) &&
            (search.during && offer.offerAppointments.length > 0
              ? offer.offerAppointments.some((oa) => {
                  if (!search.during) return true
                  const date = new Date(oa.timestamp)
                  return search.during[0] <= date && date <= search.during[1]
                })
              : true)
          )
        }) ?? []

      return {
        component: AlOffersGridSection,
        props: { offers },
        empty: offers.length === 0,
        data: offers,
      } as const
    })
})

const { data: page, suspense: landingPageSuspense } = useLandingPageByType('homepage')
onServerPrefetch(async () => {
  await Promise.all([landingPageSuspense(), markerSuspense()])
})
useSeoMeta({
  title: computed(() => page.value?.title ?? ''),
})

const markerName = computed(() =>
  match(homePageType.value)
    .with('artist', () => 'Künstler:innen')
    .with('location', () => 'Kunsträume')
    .with('offer', () => 'Angebote')
    .with('region', () => 'Regionen')
    .exhaustive(),
)

function openCookieBanner() {
  window.falconCookie?.open()
}

const markerBaseUrl = computed(
  () =>
    `/${match(homePageType.value)
      .with('location', () => 'kunstraum')
      .with('offer', () => 'angebot')
      .with('region', () => 'region')
      .with('artist', () => 'kuenstler-in')
      .exhaustive()}`,
)
</script>

<template>
  <div v-if="page" class="relative">
    <!-- Map -->
    <div class="relative">
      <div class="absolute right-0 z-30 w-full">
        <AlPadding class="flex items-center justify-center">
          <ClientOnly>
            <AlSearchbar
              v-model:displayType="displayType"
              v-model:search="search"
              :home-page-type="bookAtelierOverride ? 'offer' : homePageType"
            />
          </ClientOnly>
        </AlPadding>
      </div>

      <template v-if="displayType === 'map'">
        <div
          class="pointer-events-none absolute top-0 z-20 h-24 w-full bg-gradient-to-b from-white to-transparent"
        />

        <KeepAlive>
          <ClientOnly>
            <template #default>
              <AlMap
                v-if="googleMapsUnlocked"
                :coords="displayInfo.data ?? []"
                :marker-size="homePageType === 'artist' ? 160 : 288"
                :marker-name="markerName"
                @marker-click="
                  (point) => {
                    navigateTo(`${markerBaseUrl}/${point.slug}`)
                  }
                "
              >
                <template #marker="{ point }">
                  <AlArtistAvatar
                    v-if="homePageType === 'artist'"
                    :artist="point"
                    class="h-40 w-40"
                  />
                  <div v-else class="relative block h-44 w-44 md:h-60 md:w-60 lg:h-72 lg:w-72">
                    <img
                      :src="point.thumbnail.src"
                      :alt="point.thumbnail.alt"
                      class="h-full w-full object-cover"
                    />
                    <div
                      class="absolute inset-0 grid grid-cols-1 items-end break-words bg-gradient-to-b from-transparent via-black/20 to-black/40 p-4 font-sans-header text-2xl text-white md:text-3xl lg:text-4xl"
                    >
                      {{ point.name }}
                    </div>
                  </div>
                </template>
              </AlMap>
              <div
                v-else
                class="relative flex h-[90vh] w-full flex-col items-center justify-center"
              >
                <UCard class="m-4">
                  <p class="mb-4 text-lg">
                    Um die Karte zu sehen, müssen Sie zuerst die Cookies akzeptieren.
                  </p>
                  <div class="text-end">
                    <UButton
                      size="xl"
                      class="max-md:w-full"
                      icon="i-ph-cookie-fill"
                      @click="() => openCookieBanner()"
                    >
                      Cookie-Einstellungen öffnen
                    </UButton>
                  </div>
                </UCard>
                <img
                  class="absolute -z-10 h-full w-full object-cover blur-sm"
                  src="/map_placeholder.webp"
                />
              </div>
            </template>
            <template #fallback>
              <img class="h-[90vh] w-full object-cover" src="/map_placeholder.webp" />
            </template>
          </ClientOnly>
        </KeepAlive>
      </template>

      <template v-else-if="displayType === 'grid'">
        <div class="pb-16 pt-20">
          <component
            :is="displayInfo.component ?? ''"
            v-if="!displayInfo.empty"
            v-bind="displayInfo.props"
          />
          <AlPadding v-else>
            <div class="text-center text-lg">Für diese Suche gibt es keine Treffer.</div>
          </AlPadding>
        </div>
      </template>
    </div>

    <div class="mt-12">
      <AlCmsPageSection
        v-for="section of page.cms_page_section"
        :key="section.id"
        :section="section"
      />

      <AlLandingPageShowcase />
    </div>
  </div>
  <div v-else />
</template>
