import { Loader } from '@googlemaps/js-api-loader'

export function useGoogleMaps(callback?: (maps: google.maps.MapsLibrary) => any) {
  const config = useRuntimeConfig()
  const loader = new Loader({
    apiKey: config.public.googleMapsApiKey,
    version: 'weekly',
  })
  const { data } = useAsyncData(async () => loader.importLibrary('maps'), {
    server: false,
    lazy: true,
  })

  watch(
    data,
    (maps) => {
      if (!maps) return
      callback?.(maps)
    },
    {
      immediate: true,
    },
  )

  return data
}
