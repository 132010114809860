<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { OnClickOutside } from '@vueuse/components'
import { uniqBy } from 'remeda'

defineProps<{
  homePageType: 'artist' | 'location' | 'offer' | 'region'
}>()

const displayType = defineModel<string>('displayType', { required: true })

export type SearchQuery = {
  name: string
  city: string
  during: [from: Date, to: Date] | null
  type: { id: string; name: string }[]
  artForm: { id: string; name: string }[]
}

const search = defineModel<SearchQuery>('search', {
  default: { name: '', during: null, city: '', type: [], artForm: [] } satisfies SearchQuery,
})

const supabase = useSupabase()
const cities = reactive(
  useQuery({
    queryKey: ['search-cities'] as const,
    queryFn: async () => {
      const cities = await supabase.from('address').select('id, city')
      return uniqBy(cities.data ?? [], (c) => c.city)
    },
    placeholderData: [],
  }),
)

const types = reactive(
  useQuery({
    queryKey: ['search-types'] as const,
    queryFn: async () => {
      const types = await supabase.from('offer_type').select('id, name')
      return [{ id: 'book-atelier', name: 'Atelier mieten' }, ...(types.data ?? [])]
    },
    placeholderData: [],
  }),
)

const artForms = reactive(
  useQuery({
    queryKey: ['search-artforms'] as const,
    queryFn: async () => {
      const artForms = await supabase.from('art_form').select('id, name')
      return artForms.data ?? []
    },
    placeholderData: [],
  }),
)

const mobileExpandedToggle = ref(false)
const mobileExpanded = computed(
  () => mobileExpandedToggle.value || useMediaQuery('(min-width: 1024px)' /* lg */).value,
)

const searchName = ref('')
watchDebounced(
  searchName,
  () => {
    search.value.name = searchName.value
  },
  { debounce: 200, maxWait: 1000 },
)

const searchCity = ref<{ id: string; city: string }>() // <USelectMenu />
watch(searchCity, (searchCityValue) => {
  if (searchCityValue?.id === '_deselect') {
    searchCity.value = undefined
    return
  }

  search.value.city = searchCityValue?.city ?? ''
})
</script>

<template>
  <div
    class="flex w-full items-center justify-center gap-4 rounded-full border border-stone-300/40 bg-white p-4 shadow-card max-lg:flex-wrap lg:justify-between lg:p-1.5"
    :class="{ '!rounded-[2rem]': mobileExpanded }"
  >
    <OnClickOutside class="contents" @trigger="() => (mobileExpandedToggle = false)">
      <div
        class="grid w-full grid-cols-1 flex-col items-center gap-2 sm:grid-cols-2 lg:flex lg:flex-row"
      >
        <UInput
          v-model="searchName"
          variant="none"
          placeholder="Suche..."
          icon="i-ph-magnifying-glass-bold"
          :ui="{ icon: { base: 'text-al-secondary !size-4' } }"
          class="col-span-full flex-grow basis-0 xl:flex-grow-[2]"
          @focus="() => (mobileExpandedToggle = true)"
        />
        <div v-show="mobileExpanded" class="contents">
          <USelectMenu
            v-model="searchCity"
            :options="
              [{ id: '_deselect', city: 'Auswahl entfernen' }].concat(
                cities.data?.toSorted((a, b) => a.city.localeCompare(b.city)) ?? [],
              )
            "
            :loading="cities.isPending"
            variant="none"
            placeholder="Ort"
            icon="i-ph-map-pin"
            by="id"
            option-attribute="city"
            :ui="{ icon: { base: '!size-4' } }"
            searchable
            searchable-placeholder="Suche..."
            clear-search-on-close
            class="flex-1"
          >
            <template #option="{ option }">
              <UIcon v-if="option.id === '_deselect'" name="i-ph-x" class="size-4" />
              <span class="truncate">{{ option.city }}</span>
            </template>
            <template #option-empty="{ query }">
              <q>{{ query }}</q>
              nicht gefunden
            </template>
          </USelectMenu>
          <AlDateRangePicker
            v-if="homePageType === 'offer'"
            v-model="search.during"
            class="flex-1 pl-2.5"
          />
          <USelectMenu
            v-if="homePageType === 'offer'"
            v-model="search.type"
            :options="types.data?.toSorted((a, b) => a.name.localeCompare(b.name))"
            option-attribute="name"
            by="id"
            :loading="types.isPending"
            variant="none"
            placeholder="Art"
            icon="i-ph-tag"
            :ui="{ icon: { base: '!size-4' } }"
            multiple
            :ui-menu="{
              label: 'text-gray-400',
              option: { selectedIcon: { base: 'text-al-primary-400' } },
            }"
            class="flex-1"
          >
            <template v-if="search.type.length > 0" #label>
              <span class="truncate">{{ search.type.length }} ausgewählt</span>
            </template>
          </USelectMenu>
          <USelectMenu
            v-if="homePageType === 'offer'"
            v-model="search.artForm"
            :options="artForms.data?.toSorted((a, b) => a.name.localeCompare(b.name))"
            option-attribute="name"
            by="id"
            :loading="artForms.isPending"
            variant="none"
            placeholder="Kunstform"
            icon="i-ph-paint-brush"
            :ui="{ icon: { base: '!size-4' } }"
            multiple
            :ui-menu="{
              label: 'text-gray-400',
              option: { selectedIcon: { base: 'text-al-primary-400' } },
            }"
            class="flex-1"
          >
            <template v-if="search.artForm.length > 0" #label>
              <span class="truncate">{{ search.artForm.length }} ausgewählt</span>
            </template>
          </USelectMenu>
          <div
            class="flex gap-2 max-sm:mx-auto sm:ml-auto lg:ml-4"
            :class="{ 'col-span-full mx-auto': homePageType === 'offer' }"
          >
            <AlClassRadio v-model="displayType" icon="i-ph-map-trifold" class-value="map" />
            <AlClassRadio v-model="displayType" icon="i-heroicons-queue-list" class-value="grid" />
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>
