<script lang="ts" setup generic="T extends Coordinates">
import { equals } from 'remeda'
import Supercluster from 'supercluster'

import type { Coordinates } from '~/utils/types'

const props = defineProps<{
  coords: T[]
  markerSize: number
  markerName: string
}>()

const emit = defineEmits<{
  markerClick: [point: T]
}>()

defineSlots<{
  marker: (slotProps: { point: T }) => void
}>()

const mapElement = ref<HTMLDivElement>()
const map = shallowRef<google.maps.Map>()

const maxZoom = 18
const minZoom = 7
const defaultZoom = 10
const clusterer = computed(
  () =>
    new Supercluster({
      radius: props.markerSize,
      extent: props.markerSize * 2,
      minZoom,
      maxZoom,
    }),
)
const markerCluster = computed(() =>
  clusterer.value.load(
    props.coords.map((c) => ({
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [c.lng, c.lat] },
      properties: c,
    })),
  ),
)
const zoom = ref(map.value?.getZoom() ?? defaultZoom)
const bounds = shallowRef<google.maps.LatLngBoundsLiteral>()
const boundsDebounced = debouncedRef(bounds, 100, {
  maxWait: 500,
})

type Marker =
  | {
      point: T
      key: string
      isCluster: false
    }
  | {
      point: T
      count: number
      key: number
      isCluster: true
    }
const markers = ref<Marker[]>()

watch([map, markerCluster], () => {
  updateMarkers()
})

watch(boundsDebounced, (newBounds, oldBounds) => {
  if (equals(newBounds, oldBounds)) return

  updateMarkers()
})

function updateMarkers() {
  if (!zoom.value || !bounds.value) return
  const _bounds = bounds.value
  const _zoom = zoom.value

  markers.value = markerCluster.value
    .getClusters([_bounds.west, _bounds.south, _bounds.east, _bounds.north], _zoom)
    .map((c) => {
      const [lng, lat] = c.geometry.coordinates

      if (c.properties.cluster) {
        return {
          isCluster: c.properties.cluster,
          count: c.properties.point_count,
          key: c.properties.cluster_id,
          point: { lat, lng } as T,
        }
      }

      return {
        isCluster: false,
        key: `${lat}-${lng}`,
        point: { lat, lng, ...c.properties } as T,
      }
    })
}

const googleMaps = useGoogleMaps()
const config = useRuntimeConfig()

const updateZoomAndBounds = useDebounceFn(
  () => {
    if (!map.value) return
    zoom.value = map.value.getZoom() ?? defaultZoom

    const mapBounds = map.value.getBounds()
    bounds.value = mapBounds && {
      west: mapBounds.getSouthWest().lng(),
      south: mapBounds.getSouthWest().lat(),
      east: mapBounds.getNorthEast().lng(),
      north: mapBounds.getNorthEast().lat(),
    }
  },
  100,
  { maxWait: 300 },
)

watch(
  () => props.coords,
  (coords, prevCoords) => {
    if (coords.length === 0) {
      updateMarkers()
      return
    }

    const newBounds: google.maps.LatLngBoundsLiteral = {
      west: Number.POSITIVE_INFINITY,
      south: Number.POSITIVE_INFINITY,
      east: Number.NEGATIVE_INFINITY,
      north: Number.NEGATIVE_INFINITY,
    }

    for (const coord of coords) {
      newBounds.west = Math.min(newBounds.west, coord.lng)
      newBounds.south = Math.min(newBounds.south, coord.lat)
      newBounds.east = Math.max(newBounds.east, coord.lng)
      newBounds.north = Math.max(newBounds.north, coord.lat)
    }

    map.value?.fitBounds(newBounds, 100)
    updateZoomAndBounds()

    if (prevCoords.length === 0) updateMarkers()
  },
)

watch(
  [googleMaps, mapElement],
  ([maps, newMapElement]) => {
    if (!maps || !newMapElement) return

    map.value = new maps.Map(newMapElement, {
      center: { lat: 51.034_825_2, lng: 13.700_429_6 },
      mapId: config.public.googleMapsMapId,
      zoom: defaultZoom,
      disableDefaultUI: true,
      minZoom,
      maxZoom,
    })
    if (!map.value) return

    map.value.addListener('zoom_changed', () => updateZoomAndBounds())
    map.value.addListener('bounds_changed', () => updateZoomAndBounds())

    let mapLoaded = false
    map.value.addListener('idle', () => {
      if (mapLoaded) return
      mapLoaded = true
      updateZoomAndBounds()
    })
  },
  { immediate: true },
)

const hoveredMarkerKey = ref<string>()

function appear(el: HTMLElement) {
  setTimeout(() => (el.style.opacity = '1'), 100)
}
</script>

<template>
  <div class="relative h-[90vh] w-full">
    <div v-if="map" class="hidden">
      <div v-for="marker of markers" :key="marker.key">
        <AlMarkerWrapper
          :position="{
            lat: marker.point.lat,
            lng: marker.point.lng,
          }"
          :map="map"
          :z-index="marker.key === hoveredMarkerKey ? 5 : marker.isCluster ? 10 : 0"
          @mouseenter="() => (hoveredMarkerKey = marker.key.toString())"
          @click="
            (e) => {
              const target = e.domEvent.target as HTMLElement | null
              const clickable = target?.closest('[data-clickable]') as HTMLElement | null
              if (clickable) clickable.click()
            }
          "
        >
          <div
            v-if="marker.isCluster"
            class="grid gap-1"
            :style="{
              gridTemplateColumns:
                zoom >= maxZoom
                  ? `repeat(${Math.round(Math.sqrt(markerCluster.getLeaves(marker.key).length))}, 1fr)`
                  : '',
            }"
          >
            <AlOfferClusterMarker
              v-if="zoom < maxZoom"
              data-clickable
              :marker-name="markerName"
              :marker-count="marker.count"
              @click="
                () => {
                  map?.setCenter(marker.point)
                  map?.setZoom(zoom + 2)
                }
              "
            />
            <div
              v-for="{
                geometry: {
                  coordinates: [lng, lat],
                },
                properties,
              } in markerCluster.getLeaves(marker.key)"
              v-else
              :key="properties.id"
              class="opacity-0 transition-opacity duration-200"
              data-clickable
              @vue:mounted="({ el }) => appear(el)"
              @click="() => emit('markerClick', { lat, lng, ...properties } as T)"
            >
              <slot
                name="marker"
                :point="
                  { lat, lng, ...properties } as T //
                "
              />
            </div>
          </div>
          <div v-else data-clickable @click="() => emit('markerClick', marker.point)">
            <slot name="marker" :point="marker.point" />
          </div>
        </AlMarkerWrapper>
      </div>
    </div>
    <div ref="mapElement" class="h-full w-full" />
    <div class="absolute bottom-12 right-12 flex">
      <!-- zoom is only updated by extern call back or debounced, no logic is dependent on `zoom` (only debounced version)
          => zoom can be updated directly (to remove input lag), without affecting consistency of state
      -->
      <AlMapZoom
        :model-value="zoom ?? defaultZoom"
        class="shadow-lg"
        :min="minZoom"
        :max="maxZoom"
        @update:model-value="
          (newZoom) => {
            map?.setZoom(newZoom)
            zoom = newZoom
          }
        "
      />
    </div>
  </div>
</template>
