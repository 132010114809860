<script setup lang="ts" generic="T extends string, U extends T">
const props = defineProps<{
  icon: string
  classValue: U
  modelValue: T
}>()

const v = useVModel(props, 'modelValue')
</script>

<template>
  <UButton
    :icon="icon"
    :ui="{ rounded: 'rounded-full' }"
    :class="[
      modelValue === classValue
        ? 'bg-al-primary-300'
        : 'bg-stone-100 text-al-primary-300 hover:text-stone-100',
    ]"
    class="transition-colors"
    size="xl"
    @click="() => (v = classValue as T & boolean)"
  />
</template>
