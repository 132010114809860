<script lang="ts" setup>
const props = defineProps<{
  max: number
  min: number
  modelValue: number
}>()
const zoom = useVModel(props, 'modelValue')
function safeSetZoom(newZoom: number) {
  zoom.value = Math.min(props.max, Math.max(props.min, newZoom))
}
</script>

<template>
  <div class="flex items-center gap-4 rounded-full bg-white px-6 py-4">
    <UButton
      :padded="false"
      icon="i-ph-minus-bold"
      variant="ghost"
      color="gray"
      @click="() => safeSetZoom(zoom - 1)"
    />
    <URange
      v-model="zoom"
      :min="min"
      :max="max"
      class="w-24"
      size="sm"
      :ui="{
        thumb: {
          color: 'text-al-secondary',
          background:
            '[&::-webkit-slider-thumb]:bg-al-secondary [&::-webkit-slider-thumb]:dark:bg-gray-900 [&::-moz-range-thumb]:bg-current',
        },
        progress: {
          background: 'bg-al-secondary',
        },
      }"
    />
    <UButton
      :padded="false"
      icon="i-ph-plus-bold"
      variant="ghost"
      color="gray"
      @click="() => safeSetZoom(zoom + 1)"
    />
  </div>
</template>
