<script lang="ts" setup>
const props = defineProps<{
  map: google.maps.Map
  position: {
    lat: number
    lng: number
  }
  zIndex?: number
}>()

const emit = defineEmits<{
  click: [event: google.maps.MapMouseEvent]
}>()

defineSlots<{
  default: void
}>()

const markerRef = ref<HTMLDivElement>()
let marker: google.maps.marker.AdvancedMarkerElement
onMounted(async () => {
  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    'marker',
  )) as google.maps.MarkerLibrary

  marker = new AdvancedMarkerElement({
    map: props.map,
    content: markerRef.value,
    position: props.position,
    zIndex: props.zIndex,
  })
  marker.addListener('click', (e: google.maps.MapMouseEvent) => emit('click', e))
})

watch(
  () => props.zIndex,
  (zIndex) => {
    marker.zIndex = zIndex
  },
)

onUnmounted(() => {
  marker.map = null
})
</script>

<template>
  <div ref="markerRef" class="translate-y-1/2">
    <slot />
  </div>
</template>
